import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoExtended from 'ui/components/ExtendedLogo';
export const Container = styled.div `
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
`;
export const Wrapper = styled.div `
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export const Navigation = styled.nav `
  display: flex;

  ul {
    display: flex;
    list-style: none;
    margin: auto;

    li + li {
      margin-left: 10px;
    }
  }
`;
export const Header = styled.header `
  display: flex;
  justify-content: space-between;
`;
export const Logo = styled(LogoExtended) `
  height: 76px;
  width: 240px;
`;
const ActionButton = styled(Link) `
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 500;
`;
export const Login = styled(ActionButton) ``;
export const Signup = styled(ActionButton) `
  background-color: #51339a;
`;
export const Content = styled.main `
  flex: 1;
  display: flex;
  justify-content: center;
`;
export const Title = styled.h1 `
  font-weight: 600;
  font-size: 5rem;
  margin-top: 12rem;
`;
