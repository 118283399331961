import React from 'react';
import { Navigation, Content, Title, Container, Wrapper, Login, Logo, Header, Signup, } from './styles';
export default function Landing() {
    return (React.createElement(Container, null,
        React.createElement(Wrapper, null,
            React.createElement(Header, null,
                React.createElement(Logo, null),
                React.createElement(Navigation, null,
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement(Login, { to: "/login" }, "Log In")),
                        React.createElement("li", null,
                            React.createElement(Signup, { to: "/signup" }, "Sign Up"))))),
            React.createElement(Content, null,
                React.createElement(Title, null, "read what you choose")))));
}
