import styled, { css } from 'styled-components';
export const Container = styled.svg `
  ${({ variant }) => variant === 'rounded' &&
    css `
      border-radius: 3rem;
    `};
  ${({ variant }) => variant === 'circular' &&
    css `
      border-radius: 50%;
    `};
`;
export const Background = styled.rect `
  fill: ${({ theme }) => theme.colors.primary};
`;
export const Ellipse = styled.path `
  fill: #dfcdff;
`;
export const Shadow = styled.path `
  fill-opacity: 0.995;
`;
export const Letter = styled.path `
  fill: #ebebeb;
`;
