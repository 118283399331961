var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useContext, useState, useEffect, useCallback, } from 'react';
import { login as requestLogin, logout as requestLogout, } from '../../api/auth';
import useUnmounted from '../../hooks/useUnmounted';
import http, { setAuthHeader } from '../../api/http';
export const ACCESS_TOKEN = 'newtron:access_token';
const AuthContext = createContext({
    loggedIn: false,
    login: () => new Promise(() => { }),
    logout: () => null,
});
export const useAuth = () => useContext(AuthContext);
export default function AuthContextProvider({ children }) {
    const [accessToken, setAccessToken] = useState(() => localStorage.getItem(ACCESS_TOKEN));
    const [loggedIn, setLoggedIn] = useState(false);
    const unmounted = useUnmounted();
    useEffect(() => {
        setAuthHeader(accessToken);
        setLoggedIn(Boolean(accessToken));
        if (accessToken) {
            localStorage.setItem(ACCESS_TOKEN, accessToken);
        }
        else {
            localStorage.removeItem(ACCESS_TOKEN);
        }
    }, [accessToken]);
    function login(dto) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data: { token }, } = yield requestLogin(dto);
            if (unmounted)
                return;
            setAccessToken(token);
        });
    }
    function logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield requestLogout();
                if (unmounted)
                    return;
                setAccessToken(null);
            }
            catch (error) {
                return error.response.data;
            }
        });
    }
    const refresh = useCallback((error) => __awaiter(this, void 0, void 0, function* () {
        const response = yield http.get('/refresh_token');
        if (unmounted)
            return;
        const [, token] = response.headers.Authentication.split(' ');
        setAccessToken(token);
        return error.config;
    }), [unmounted]);
    useEffect(() => {
        const interceptor = http.interceptors.response.use((success) => success, (error) => {
            if (!error.response)
                return;
            const { status } = error.response;
            if (status === 401) {
                if (error.config.url !== '/refresh_token') {
                    return refresh(error);
                }
                setAccessToken(null);
            }
            throw error;
        });
        return () => {
            http.interceptors.response.eject(interceptor);
        };
    }, [refresh]);
    return (React.createElement(AuthContext.Provider, { value: { loggedIn, login, logout } }, children));
}
