import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ComingSoon from 'ui/containers/ComingSoon';
import Landing from 'ui/containers/Landing';
export default function GuestRoutes() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/" },
            React.createElement(Landing, null)),
        React.createElement(Route, { path: "/login" },
            React.createElement(ComingSoon, null)),
        React.createElement(Route, { path: "/signup" },
            React.createElement(ComingSoon, null)),
        React.createElement(Route, { path: "*" },
            React.createElement(Redirect, { to: "/" }))));
}
