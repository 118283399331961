import axios from 'axios';
const http = axios.create({
    baseURL: 'http://localhost:8080/api',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
export function setAuthHeader(token) {
    if (!token) {
        delete http.defaults.headers.Authorization;
    }
    else {
        http.defaults.headers.Authorization = `Bearer ${token}`;
    }
}
export default http;
