import { useRef, useEffect } from 'react';
export default function useUnmounted() {
    const unmountedRef = useRef(true);
    useEffect(() => {
        return () => {
            unmountedRef.current = false;
        };
    }, []);
    return !unmountedRef.current;
}
