var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { LettersContainer, Letter, Wrapper, Link } from './styles';
import Logo from '../Logo';
const variants = {
    open: {
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};
const LogoExtended = (_a) => {
    var { animated = true, to } = _a, props = __rest(_a, ["animated", "to"]);
    const content = (React.createElement(React.Fragment, null,
        React.createElement(Logo, null),
        React.createElement(LettersContainer, Object.assign({ variants: {
                open: {
                    transition: {
                        staggerChildren: 0.07,
                        delayChildren: 0.2,
                    },
                },
                closed: {},
            } }, (animated && { initial: 'closed', animate: 'open' })), 'ewtron'.split('').map((ch) => (React.createElement(Letter, { key: ch, variants: variants, "data-content": ch }, ch))))));
    return to ? (React.createElement(Link, Object.assign({ to: to }, props), content)) : (React.createElement(Wrapper, Object.assign({}, props), content));
};
export default LogoExtended;
