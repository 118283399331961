import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle';
import AuthContextProvider from './context/auth';
import Navigation from './ui/containers/Navigation';
import themes from './styles/themes';
const App = () => {
    return (React.createElement(ThemeProvider, { theme: themes.tron },
        React.createElement(AuthContextProvider, null,
            React.createElement(GlobalStyle, null),
            React.createElement(Navigation, null))));
};
export default App;
