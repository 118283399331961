import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Link as DefaultLink } from 'react-router-dom';
const container = css `
  display: flex;
  position: relative;
  height: 76px;
  width: 240px;

  > svg {
    position: relative;
    width: 25%;
    left: -1rem;

    rect {
      fill: transparent;
    }
  }
`;
export const Wrapper = styled.div `
  ${container}
`;
export const Link = styled(DefaultLink) `
  ${container}
`;
export const Letter = motion.custom(styled.span `
  font-size: 1.5rem;
  font-weight: bold;
  color: transparent;
  position: relative;
  line-height: 1.1;

  &::before {
    content: attr(data-content);
    position: absolute;
    text-shadow: 0 0 8px rgb(0, 0, 0);
    z-index: 1;
  }

  &::after {
    content: attr(data-content);
    position: absolute;
    color: #ebebeb;
    left: 0;
    z-index: 2;
  }
`);
export const LettersContainer = motion.custom(styled.div `
  position: absolute;
  left: 28px;
  top: 38%;
`);
