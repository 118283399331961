export default {
    colors: {
        primary: '#230062',
        text: 'hsla(0, 0%, 82%)',
        textSecondary: 'hsla(0, 0%, 82%, .7)',
        yellow: '#e6d346',
        navbarBackground: '#120728',
        white: '#fdfdfd',
        dark: 'rgba(0, 0, 0, .4)',
        purple200: '#afafe3',
        purple100: '#7676a8',
    },
    fonts: {
        orbitron: 'Orbitron',
        sourceSansPro: 'Source Sans Pro',
    },
};
