import styled from 'styled-components';
export const Container = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-size: 2rem;

  > h2 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  > p {
    font-size: 1.5rem;
  }
`;
