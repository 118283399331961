var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Container, Background, Ellipse, Shadow, Letter } from './styles';
export default function Logo(_a) {
    var { variant = 'default' } = _a, rest = __rest(_a, ["variant"]);
    return (React.createElement(Container, Object.assign({ viewBox: "0 0 67.3 67.3", xmlns: "http://www.w3.org/2000/svg", variant: variant }, rest),
        React.createElement("defs", null,
            React.createElement("filter", { id: "logo__shadow", x: "-.307", y: "-.302", width: "1.61", height: "1.6" },
                React.createElement("feGaussianBlur", { stdDeviation: "1.6791126" }))),
        React.createElement("g", { transform: "translate(-73.7 -72.9)" },
            React.createElement(Background, { x: "73.7", y: "72.9", width: "67.3", height: "67.3", ry: "0" }),
            React.createElement(Ellipse, { d: "m122 82.3a12.1 29.2 37.8 0 0-16.6 8.53 12.1 29.2 37.8 0 0-8.92 10.1 12.1 29.2 37.8 0 0-6.25 29.1 12.1 29.2 37.8 0 0 0.0796 0.0506 12.1 29.2 37.8 0 0 7.55-0.297 12.1 29.2 37.8 0 0 20.4-17.6 12.1 29.2 37.8 0 0 8.75-26.1 12.1 29.2 37.8 0 0-2.55-3.05l-0.0248-0.0124a12.1 29.2 37.8 0 0-2.43-0.627zm-1.99 2.99a9.47 25.7 37.3 0 1 2.41 0.421l0.0186 0.0108a9.47 25.7 37.3 0 1 2.17 3.86 9.47 25.7 37.3 0 1-8.71 21.3 9.47 25.7 37.3 0 1-15.3 14.8 9.47 25.7 37.3 0 1-8.26 1.61 9.47 25.7 37.3 0 1-0.186-0.131 9.47 25.7 37.3 0 1-5.3e-4 0 9.47 25.7 37.3 0 1 6.69-25 9.47 25.7 37.3 0 1 21.2-16.8z" }),
            React.createElement(Ellipse, { d: "m127 127a29.2 12.1 52.8 0 0-3.94-18.3 29.2 12.1 52.8 0 0-7.42-11.2 29.2 12.1 52.8 0 0-26.5-13.6 29.2 12.1 52.8 0 0-0.0695 0.0638 29.2 12.1 52.8 0 0-1.67 7.37 29.2 12.1 52.8 0 0 11.8 24.3 29.2 12.1 52.8 0 0 23 15.2 29.2 12.1 52.8 0 0 3.61-1.68l0.0184-0.0208a29.2 12.1 52.8 0 0 1.24-2.19zm-2.37-2.7a25.7 9.47 52.3 0 1-1.03 2.22l-0.0153 0.0152a25.7 9.47 52.3 0 1-4.29 1.1 25.7 9.47 52.3 0 1-18.3-13.9 25.7 9.47 52.3 0 1-10.3-18.6 25.7 9.47 52.3 0 1 0.587-8.4 25.7 9.47 52.3 0 1 0.174-0.145 25.7 9.47 52.3 0 1 1.4e-4 -5.12e-4 25.7 9.47 52.3 0 1 22.4 12.9 25.7 9.47 52.3 0 1 10.8 24.8z" }),
            React.createElement(Ellipse, { d: "m134 98.5a12.1 29.2 80.1 0 0-18-4.88 12.1 29.2 80.1 0 0-13.4 1.44 12.1 29.2 80.1 0 0-24.2 17.3 12.1 29.2 80.1 0 0 0.0248 0.091 12.1 29.2 80.1 0 0 5.78 4.87 12.1 29.2 80.1 0 0 27 0.696 12.1 29.2 80.1 0 0 24.1-13.4 12.1 29.2 80.1 0 0 0.167-3.97l-0.0101-0.0259a12.1 29.2 80.1 0 0-1.38-2.1zm-3.49 0.869a9.47 25.7 79.7 0 1 1.5 1.94l6e-3 0.0206a9.47 25.7 79.7 0 1-0.994 4.32 9.47 25.7 79.7 0 1-20.8 9.88 9.47 25.7 79.7 0 1-21.3 0.595 9.47 25.7 79.7 0 1-7.19-4.38 9.47 25.7 79.7 0 1-0.0491-0.222 9.47 25.7 79.7 0 1-3.9e-4 -3.6e-4 9.47 25.7 79.7 0 1 21.8-14 9.47 25.7 79.7 0 1 27 1.83z" }),
            React.createElement("g", { transform: "translate(-234 -3.91)" },
                React.createElement(Shadow, { transform: "matrix(2.34 0 0 2.34 196 -94.3)", d: "m55.2 81.6v12.2c2.77 0.367 4.98 0.177 4.04-3.17-0.687-4.36 1.07-2.03 2.8 0.25 1.34 2.2 3.08 3.55 5.75 2.92 0.916-1.59 0.137-4.88 0.396-7.12-0.578-1.9 1.42-6.38-1.58-5.86-3.48-1.07-2.34 2.49-2.49 4.75 0.271 3.21-2.62-2.25-3.82-3.08-0.567-1.95-4.82-2.5-5.1-0.879z", filter: "url(#logo__shadow)" }),
                React.createElement("g", { transform: "matrix(.928 0 0 .928 96.5 -143)", "aria-label": "N" },
                    React.createElement(Letter, { d: "m249 288v-28.8h6.4l16.1 19.2v-19.2h6.28v28.8h-6.4l-16.2-19.3v19.3z" }))))));
}
